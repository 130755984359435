<template>
  <div class="contact_wrap">
    <div class="img_bg">
      <div class="img_title">
        <h3>您需要什么帮助</h3>
        <h6>留下您的联系方式</h6>
      </div>
    </div>
    <!-- -------------------------------- -->
    <div class="gome_contact">
      <div class="up_card">
        <h3 class="up_card-title">联系方式</h3>
        <div class="bottom_line"></div>
      </div>
      <div class="down_card">
        <div class="down_card_sec" v-for="item in items" :key="item.ids">
          <div class="down_card_sec_left">
            <h5 class="down_card_sec_left-name">{{ item.name }}</h5>
            <div class="down_card_sec_left_bottom_line"></div>
            <p v-if="item.contact" class="down_card_sec_left_contact">招商联系人：{{ item.contact }}</p>
            <p class="down_card_sec_left_address">地址：{{ item.address }}</p>
            <p class="down_card_sec_left_text">电话：{{ item.tel }}</p>
            <p class="down_card_sec_left_text">邮箱：{{ item.mail }}</p>
          </div>
          <div class="down_card_sec_right">
            <div class="carousel_img wechat">
              <div class="code_img"></div>
            </div>
            <div class="carousel_img dou" src="@/assets/img/douDefault.png">
              <div class="code_img"></div>
            </div>
            <div class="carousel_img sina" src="@/assets/img/sinaDefault.png">
              <div class="code_img"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------------- -->
    <div class="customer_contact">
      <div class="up_card">
        <h3 class="up_card-title">联系我们</h3>
        <div class="bottom_line"></div>
      </div>
      <div class="down_card">
        <p class="down_card_title">请填写表单信息，国美家将尽快与您联系！</p>
        <div class="down_card_form">
          <h6><span>*</span>姓名</h6>
          <input v-model="message.name" placeholder="请输入姓名" />
        </div>
        <div class="down_card_form">
          <h6><span>*</span>手机</h6>
          <input v-model="message.phone" placeholder="请输入手机" />
        </div>
        <div class="down_card_form">
          <h6><span>*</span>电子邮件</h6>
          <input v-model="message.email" placeholder="请输入电子邮件" />
        </div>
        <div class="down_card_form">
          <h6><span>*</span>公司名称</h6>
          <input v-model="message.companyName" placeholder="请输入公司名称" />
        </div>
        <div class="down_card_form">
          <h6><span>*</span>主营业务</h6>
          <input v-model="message.mainBusiness" placeholder="请输入主营业务" />
        </div>
        <div class="down_card_read">
          <div class="radio_type"></div>
          <div>
            我已阅读并接受个人信息保护声明，并同意通过电话、短信、电子邮件或者其他电子方式接受国美家产品的服务信息。
          </div>
        </div>
        <button style="background: #999;">提交</button>
      </div>
    </div>
    <!-- -------------------------------- -->
  </div>
</template>

<script>
// import empty from '@/components/empty.vue'
export default {
  data() {
    return {
      message: {},
      checked: true,
      items: [
        {
          idx: "0",
          name: "国美家",
          address: "中国北京市朝阳区霄云路26号鹏润大厦B座",
          tel: "400-890-0567 >",
          mail: "liuhaisheng1@gome.com.cn >",
        },
        {
          idx: "1",
          name: "乐活秀北京国美商都",
          address: "北京市南四环丰台科技园",
          contact: "支女士",
          tel: "15652853888 >",
          mail: "zhiwenting@gome.com.cn >",
        },
        {
          idx: "2",
          name: "乐活秀西安悦秀城",
          address: "西安市长安区学府大街与书香路交口",
          contact: "高先生",
          tel: "029-89058021 >",
          mail: "gaojunning@gome.com.cn >",
        },
        {
          idx: "3",
          name: "乐活秀长沙湘江玖号",
          address: "长沙市岳麓滨江新城",
          contact: "周先生",
          tel: "15973168508 >",
          mail: "zhouguoqing2@gome.com.cn >",
        },
        {
          idx: "4",
          name: "乐活秀广州智慧城",
          address: "广州市海珠琶洲",
          contact: "李先生",
          tel: "18502152188 >",
          mail: "lizhibin3@gome.com.cn >",
        },
        {
          idx: "5",
          name: "乐活秀成都万和广场",
          address: "成都青羊区八宝街",
          contact: "胡先生",
          tel: "18684010312 >",
          mail: "huwei61@gome.com.cn >",
        },
      ],
    };
  },
  // 注册子组件
  // components:{
  //     empty
  // },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.contact_wrap {
  width: 100%;
  overflow-x: auto;

  .radio_type {
    cursor: pointer;
    vertical-align: middle;
    margin-right: 15px;
    display: inline-block;
    width: 24px;
    height: 16px;
    background: url("~@/assets/img/checked.png") no-repeat center 0;
  }
  .img_bg {
    background: url("~@/assets/img/bg_contact.png") no-repeat center 0;
    min-width: 1200px;
    height: 800px;
    margin: auto;
    position: relative;
    .img_title {
      width: 756px;
      height: 155px;
      top: 323px;
      margin: auto;
      position: relative;
    }
    .img_title h3 {
      height: 98px;
      font-size: 70px;
      color: #ffffff;
      text-align: center;
      opacity: 1;
      letter-spacing: 15.56px;
    }
    .img_title h6 {
      width: 756px;
      height: 37px;
      font-size: 26px;
      color: #ffffff;
      text-align: center;
      opacity: 1;
      letter-spacing: 10px;
      margin-top: 20px;
    }
  }
  .gome_contact {
    width: 1200px;
    margin: auto;
    position: relative;
    .up_card {
      padding-top: 100px;
      padding-bottom: 78px;
      opacity: 1;
      &-title {
        font-size: 40px;
        font-family: Hiragino Sans GB;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        color: #1f2329;
        opacity: 1;
      }
      .bottom_line {
        width: 60px;
        height: 8px;
        background: linear-gradient(to right, #f6ba4f, #ea8427);
        border-radius: 4px;
        opacity: 1;
        text-align: bottom;
        top: 10px;
        margin: auto;
        position: relative;
      }
    }
    .down_card {
      padding-top: 0px;
      padding-bottom: 100px;
      .down_card_sec {
        padding: 60px 0;
        border-bottom: 1px dashed rgba(151, 151, 151, 1);
        height: 200px;
        &_left {
          float: left;
          &-name {
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 40px;
            color: #000000;
            opacity: 1;
          }
          &_bottom_line {
            width: 40px;
            height: 8px;
            background: linear-gradient(to right, #f6ba4f, #ea8427);
            border-radius: 4px;
            opacity: 1;
            text-align: bottom;
            top: 10px;
            position: relative;
          }
          &_address {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 40px;
            color: #666666;
            top: 10px;
            position: relative;
          }
          &_contact {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 40px;
            color: #666666;
            top: 10px;
            position: relative;
          }
          &_text {
            top: 10px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 40px;
            color: #eb8729;
            // color: #666666;
            position: relative;
          }
        }
        &_right {
          float: right;
          flex-direction: row;
          margin-top: 69px;
          .carousel_img {
            margin-left: 20px;
            display: inline-block;
            position: relative;
            width: 50px;
            height: 50px;
            //微信都应微博显示框
            .code_img {
              top: 59px;
              left: -81px;
              width: 213px;
              height: 244px;
              background-color: #fff13d;
              position: absolute;
              display: none;
            }
            // 微信抖音微博悬浮显示框
            // &:hover {
            //   .code_img {
            //     display: block;
            //   }
            // }
          }
          .wechat {
            background: url("~@/assets/img/wechatDefault.png") no-repeat center
              0;
            // &:hover {
            //   cursor: pointer;
            //   background: url("~@/assets/img/wechatSelected.png") no-repeat
            //     center 0;
            // }
          }
          .dou {
            background: url("~@/assets/img/douDefault.png") no-repeat center 0;
            // &:hover {
            //   cursor: pointer;
            //   background: url("~@/assets/img/douSelected.png") no-repeat center
            //     0;
            // }
          }
          .sina {
            background: url("~@/assets/img/sinaDefault.png") no-repeat center 0;
            // &:hover {
            //   cursor: pointer;
            //   background: url("~@/assets/img/sinaSelected.png") no-repeat center
            //     0;
            // }
          }
        }
      }
    }
  }
  .down_card_read {
    display: flex;
    align-items: flex-start;

    .radio_type {
      margin-top: 4px;
    }
    div {
      text-align: left;
      vertical-align: top;
    }
  }
  .customer_contact {
    background-color: #f7f8f9;
    height: 955px;
    .up_card-title {
      font-size: 40px;
      font-family: Hiragino Sans GB;
      font-weight: 600;
      line-height: 40px;
      text-align: center;
      color: #1f2329;
      padding-top: 100px;
    }
    .bottom_line {
      width: 60px;
      height: 8px;
      background: linear-gradient(to right, #f6ba4f, #ea8427);
      border-radius: 4px;
      opacity: 1;
      text-align: bottom;
      top: 10px;
      margin: auto;
      position: relative;
      margin-bottom: 60px;
    }
    .down_card {
      width: 1200px;
      margin: auto;
      background-color: #ffffff;
      padding: 60px;
      margin-bottom: 140px;
      &_title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #666666;
        margin-bottom: 30px;
      }
      &_form {
        margin: auto;
        width: 600px;
        margin-bottom: 30px;
      }
      h6 {
        display: inline-block;
        font-size: 14px;
        font-family: Microsoft YaHei;
        color: #333333;
        font-weight: 400;
        line-height: 19px;
        text-align: right;
        width: 70px;
        margin-right: 15px;
      }
      span {
        color: #f08057;
      }
      input {
        box-sizing: border-box;
        width: 500px;
        margin: auto;
        border: 1px solid rgba(230, 230, 230, 1);
        height: 40px;
        padding: 0px 15px;
        :-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #cccccc;
        }
        :-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #cccccc;
        }
        :-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #cccccc;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #cccccc;
        }
      }
      &_read {
        width: 539px;
        height: 48px;
        padding-left: 358px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #999999;
        margin-bottom: 30px;
      }
      input {
        width: 500px;
        height: 40px;
      }
      button {
        height: 45px;
        width: 340px;
        margin-left: 430px;
        box-align: center;
        background: linear-gradient(to right, #999, #999);
        // background: linear-gradient(to right, #f6ba4f, #ea8427);
        color: #fff;
        border: none;
        box-shadow: 0px 6px 10px 0px #999;
        // box-shadow: 0px 6px 10px 0px rgba(237, 134, 43, 0.16);
        border-radius: 3px;
      }
      // button:hover {
      //   box-shadow: 0px 6px 10px 0px rgba(237, 134, 43, 0.16);
      //   border-radius: 3px;
      // }
      // button:active {
      //   transform: translateY(4px);
      // }
    }
  }
}
</style>
